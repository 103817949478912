import React from 'react';
import { ImageType } from 'types/CommonTypes';
import {
    Description,
    Image,
    ImageMobile,
    LeftWrapper,
    RightWrapper,
    StyledSectionWrapper,
    SectionTextWrapper,
    Title,
    SectionTextWithDot,
    SectionTextWithDotWrapper,
} from './WaweloPartner.styled';

import { H2, SectionText } from 'styles/Typography.styled';

export interface WaweloPartnerProps {
    titleLeft: string;
    titleRight: string;
    descriptionLeft: string;
    descriptionRight: string;
    image: ImageType;
    repeater: { description: string }[];
}

export const WaweloPartner = ({
    descriptionLeft,
    descriptionRight,
    repeater,
    titleLeft,
    titleRight,
    image,
}: WaweloPartnerProps) => {
    return (
        <StyledSectionWrapper>
            <LeftWrapper>
                <Image alt={image?.altText} src={image?.sourceUrl} />
                <ImageMobile alt={image?.altText} src={image?.sourceUrl} />
                <Title>{titleLeft}</Title>
                <Description>{descriptionLeft}</Description>
            </LeftWrapper>
            <RightWrapper>
                <H2>{titleRight}</H2>
                <SectionTextWrapper>
                    <SectionText>{descriptionRight}</SectionText>
                    {repeater?.map((item, index) => (
                        <SectionTextWithDotWrapper key={index}>
                            <SectionTextWithDot>
                                {item?.description}
                            </SectionTextWithDot>
                        </SectionTextWithDotWrapper>
                    ))}
                </SectionTextWrapper>
            </RightWrapper>
        </StyledSectionWrapper>
    );
};
