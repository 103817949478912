import { UniversalHero } from 'components/UniversalHero/UniversalHero';
import * as React from 'react';
import Layout from 'layout/index';

import { WaweloPartner } from 'sections/WaweloPartner/WaweloPartner';

import { WaweloDetails } from 'sections/WaweloDetails/WaweloDetails';
import { getDisabledPages } from 'utils/utils';

interface WaweloFinancesPageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: WaweloFinancesPageProps) => {
    const partnersData =
        pageContext?.data?.wpInvestment?.investment?.landingWawelo;

    const pageTitle = `${pageContext?.title} - ${partnersData?.title}`;

    return (
        <Layout
            displayPartnersPage={
                pageContext?.data?.wpInvestment?.investment?.enablePartnersPage
            }
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            pageTitle={pageTitle}
            location={location}
            displayBanner
            investmentDisabledPages={getDisabledPages(
                pageContext?.data?.wpInvestment?.investment,
            )}
        >
            <>
                <UniversalHero
                    background={partnersData?.titleBackground?.sourceUrl}
                    text={partnersData?.description}
                    title={partnersData?.title}
                    subPageTitle={partnersData?.subtitle}
                />
                <WaweloPartner
                    descriptionLeft={partnersData?.workingAreaDescription}
                    descriptionRight={partnersData?.whyUsDescription}
                    image={partnersData?.logo}
                    titleLeft={partnersData?.workingAreaTitle}
                    titleRight={partnersData?.whyUsTitle}
                    repeater={partnersData?.whyUsList}
                />
                <WaweloDetails
                    titleTop={partnersData?.additionalInformation?.[0]?.title}
                    descriptionTop={
                        partnersData?.additionalInformation?.[0]?.description
                    }
                    titleBottom={
                        partnersData?.additionalInformation?.[1]?.title
                    }
                    descriptionBottom={
                        partnersData?.additionalInformation?.[1]?.description
                    }
                    imageBottom={partnersData?.image2}
                    imageTop={partnersData?.image1}
                />
            </>
        </Layout>
    );
};
