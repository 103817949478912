import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { SectionWrapper, SectionText, H2 } from 'styles/Typography.styled';

export const StyledSectionWrapper = styled(SectionWrapper)`
    display: flex;
    margin-bottom: 100px;

    ${mediaQueries.md} {
        padding: 0 16px;
    }
    ${mediaQueries.sm} {
        flex-direction: column;
        gap: 40px;
    }
`;

export const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 100px;
    padding-right: 100px;

    ${mediaQueries.sm} {
        gap: 40px;
        padding-right: 0;
    }
`;

export const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 40px;

    ${mediaQueries.sm} {
        order: -1;
    }
`;

export const Image = styled.img`
    max-width: 100%;

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const ImageMobile = styled.img`
    display: none;

    ${mediaQueries.sm} {
        display: block;
    }
    ${mediaQueries.xs} {
        max-width: 100%;
    }
`;

export const TextContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;
