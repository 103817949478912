import React from 'react';
import { ImageType } from 'types/CommonTypes';
import {
    ImageMobile,
    LeftWrapper,
    RightWrapper,
    StyledSectionWrapper,
    TextContentWrapper,
    Image,
} from './WaweloDetails.styled';

import { H2, SectionText } from 'styles/Typography.styled';

export interface WaweloDetailsProps {
    descriptionBottom: string;
    descriptionTop: string;
    imageBottom: ImageType;
    imageTop: ImageType;
    titleBottom: string;
    titleTop: string;
}

export const WaweloDetails = ({
    descriptionBottom,
    descriptionTop,
    imageBottom,
    imageTop,
    titleBottom,
    titleTop,
}: WaweloDetailsProps) => {
    return (
        <StyledSectionWrapper>
            <LeftWrapper>
                <TextContentWrapper>
                    <H2>{titleTop}</H2>
                    <SectionText>{descriptionTop}</SectionText>
                </TextContentWrapper>
                <TextContentWrapper>
                    <H2>{titleBottom}</H2>
                    <SectionText>{descriptionBottom}</SectionText>
                </TextContentWrapper>
            </LeftWrapper>
            <RightWrapper>
                <Image alt={imageTop?.altText} src={imageTop?.sourceUrl} />
                <Image
                    alt={imageBottom?.altText}
                    src={imageBottom?.sourceUrl}
                />
                <ImageMobile
                    alt={imageTop?.altText}
                    src={imageTop?.sourceUrl}
                />
                <ImageMobile
                    alt={imageBottom?.altText}
                    src={imageBottom?.sourceUrl}
                />
            </RightWrapper>
        </StyledSectionWrapper>
    );
};
